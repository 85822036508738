import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';


export const squareImage = graphql`
    fragment squareImage on File {
        childImageSharp {
            fixed(width: 150, height: 150) {
                ...GatsbyImageSharpFixed
            }
        }
    }
`;

export const query = graphql`
    query {
        ls2017: file(relativePath: { eq: "papers/ls2017.png" }) {
            ...squareImage
        }
        ls2019a: file(relativePath: { eq: "papers/ls2019a.png" }) {
            ...squareImage
        }
        ls2019b: file(relativePath: { eq: "papers/ls2019b.png" }) {
            ...squareImage
        }
        bl2022: file(relativePath: { eq: "papers/bl2022.png" }) {
            ...squareImage
        }
        l2023: file(relativePath: { eq: "papers/l2023.png" }) {
            ...squareImage
        }
    }
`;

const IndexPage = ({ data }) => {
    return (
        <Layout>

            <h4>About me</h4>
            <p>
            My name is Jan Malte Lichtenberg and I'm an applied machine learning scientist at Amazon Music ML in 
            Berlin, where I work on the fascinating topic of personalizing music recommendations. More specifically,
            I work on offline policy evaluation and learning how to rank items from different content types. 
            </p> <p>
            Previously I completed my PhD at the  <a href="https://www.bath.ac.uk/">University of Bath</a>, working on bounded rationality in reinforcement learning 
            with <a href="https://www.hardingcenter.de/en/the-harding-center/team/ozgur-simsek">Özgür Şimşek</a>. 
            Before that I worked at the <a href="https://www.mpib-berlin.mpg.de/research/concluded-areas/center-for-adaptive-behavior-and-cognition">Center for Adaptive Behaviour and Cognition</a>, directed by <a href="https://www.mpib-berlin.mpg.de/en/staff/gerd-gigerenzer">Gerd Gigerenzer</a>, at the Max-Planck 
            Insitute for Human Development in Berlin. 
            </p> <p>
            In my research, I try to inform machine learning models with insights from human decision making research, in particular from the study of <a href="https://www.mpib-berlin.mpg.de/de/forschung/adaptives-verhalten-und-kognition/publikationen/buecher/simple-heuristics/">simple decision heuristics</a>. 
            {/* I've received Master degress in statistics from ENSAE Paris and Humboldt University of Berlin. */}
            &nbsp; Have a look at my <a href="https://scholar.google.de/citations?user=QmL5QfQAAAAJ&hl=en">Google Scholar</a> profile, my <a href="https://github.com/janmaltel/">github</a>, or my (outdated) <a href={`/CV_Lichtenberg.pdf`} download>CV</a>. 
            {/* Follow me on <a href="https://twitter.com/JanMalteL">Twitter</a> to stay informed about new blog posts and other updates. */}
            </p> <p>
            You can email me at <b>maltelichtenberg ~at~ gmail.com</b>
            </p>
            <h4>Publications</h4>
            
            <div>
                <div id="l2023" className="publication">
                    <div className="publication-image">
                        <Img fixed={data.l2023.childImageSharp.fixed} alt="l2023" />
                    </div>
                    <div className="publication-info">
                        <b>Bounded Rationality in Reinforcement Learning </b> <br className="d-none d-lg-block" />
                        Jan Malte Lichtenberg <br className="d-none d-lg-block" />
                        <i>University of Bath, UK, </i>2023 <a href={`/Lichtenberg_2023_PhD_thesis.pdf`} download>[pdf]</a>
                    </div>
                </div>
                <div id="bl2022" className="publication">
                    <div className="publication-image">
                        <Img fixed={data.bl2022.childImageSharp.fixed} alt="bl2022" />
                    </div>
                    <div className="publication-info">
                        <b>Low-variance Estimation in the Plackett-Luce Model </b> <br className="d-none d-lg-block" />
                        <b>via Quasi-Monte Carlo Sampling</b> <br className="d-none d-lg-block" />
                        Alexander Buchholz, Jan Malte Lichtenberg, Giuseppe Di Benedetto, Yannik Stein, Vito Bellini, and Matteo Ruffini <br className="d-none d-lg-block" />
                        <i>SIGIR 2022 Workshop on Reaching Efficiency in Neural Information Retrieval, </i>2022 <a href="https://arxiv.org/pdf/2205.06024.pdf">[pdf]</a>
                    </div>
                </div>
                <div id="ls2019a" className="publication">
                    <div className="publication-image">
                        <Img fixed={data.ls2019a.childImageSharp.fixed} alt="ls2019a" />
                    </div>
                    <div className="publication-info">
                        <b>Regularization in Directable Environments with Application to Tetris</b> <br className="d-none d-lg-block" />
                        Jan Malte Lichtenberg and Özgür Şimşek <br className="d-none d-lg-block" />
                        <i>International Conference on Machine Learning (ICML),</i> 2019 <br />
                        <a href="http://proceedings.mlr.press/v97/lichtenberg19a/lichtenberg19a.pdf">[pdf]</a> &nbsp;
                        <a href="https://github.com/janmaltel/stew-tetris">[code]</a>
                    </div>
                </div>

                <div id="ls2019b" className="publication">
                    <div className="publication-image">
                        <Img fixed={data.ls2019b.childImageSharp.fixed} alt="ls2019b" />
                    </div>
                    <div className="publication-info">
                        <b>Iterative Policy Space Expansion for </b> <br className="d-none d-lg-block" />
                        <b>Reinforcement Learning</b> <br className="d-none d-lg-block" />
                        Jan Malte Lichtenberg and Özgür Şimşek <br className="d-none d-lg-block" />
                        <i>NeurIPS workshop on Biological and Artificial Reinforcement Learning, </i>2019 <a href="https://arxiv.org/pdf/1912.02532.pdf">[pdf]</a>
                    </div>
                </div>

                <div id="ls2017" className="publication">
                    <div className="publication-image">
                        <Img fixed={data.ls2017.childImageSharp.fixed} alt="ls2017" />
                    </div>
                    <div className="publication-info">
                        <b>Simple Regression Models</b>, <br className="d-none d-lg-block" />
                        Jan Malte Lichtenberg and Özgür Şimşek <br className="d-none d-lg-block" />
                        <i>Imperfect Decision Makers: Admitting Real-World Rationality, PMLR, </i> 2017  <a href="http://proceedings.mlr.press/v58/lichtenberg17a/lichtenberg17a.pdf">[pdf]</a>
                    </div>
                </div>
            </div>

            <h4>About this website</h4>
            <p>
                This website is made using <a href="https://gatsbyjs.org/">gatsby.js</a> and <a href="https://reactjs.org/">react.js</a>. It is hosted on <a href="https://netlify.com/">netlify.com</a>. Sidenotes and marginnotes use <a href="https://github.com/edwardtufte/tufte-css">tufte-css</a>. All visualisations are made using <a href="https://p5js.org/">p5.js</a>. Help from <a href="https://www.coreygo.com/">Corey Gouker</a> in making p5.js and gatsby.js work well together is much appreciated.
            </p>
        </Layout>
    );
};

export default IndexPage;
